<template>
<div>

  <div style="display: flex;flex-direction: row;justify-content: space-between;">

    <div style="display: flex">
      <el-select v-model="value" placeholder="请选择" style="margin: 8px" @change="getData">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-select v-model="getMonth" placeholder="请选择" style="margin: 8px" @change="getData">
        <el-option
          v-for="item in months"
          :key="item.label"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>
    <p style="font-size: 2.2em;font-weight: bold;margin-top: 10px">{{title}}</p>
    <div>
      <el-button v-on:click="goToInitData"  type="primary" style="max-height: 50px;margin: 8px">查看原始数据</el-button>
      <el-button @click="exportTable"    type="primary" style="max-height: 50px;margin: 8px">导出报表</el-button>
    </div>
  </div>

  <el-table id="myTable" :data="dayFormData" border >
    <el-table-column
      label="中心站"
      fixed
      width="125"
      prop="centerStation">
    </el-table-column>
    <el-table-column
      label="机组"
      fixed
      width="170"
      prop="stationName">
    </el-table-column>
    <el-table-column
      label="日计划用量"
      width="100"
      prop="dayTarget">
    </el-table-column>
<!--    <el-table-column-->
<!--      label="年计划用量"-->
<!--      width="100"-->
<!--      prop="yearPlan">-->
<!--    </el-table-column>-->

<!--    <el-table-column-->
<!--      label="年计划剩余用量"-->
<!--      width="130"-->
<!--      prop="yearPlanResidue">-->
<!--    </el-table-column>-->

    <el-table-column
      key="年"
      label="详细数据">
      <el-table-column v-for="(item,index) in days" :key="item" :label="item" width="90" >
        <template slot-scope="scope">
          <span >{{ scope.row.meterDataList[index].meterData}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="合计"
        prop="bigSum">
      </el-table-column>
    </el-table-column>
  </el-table>


</div>
</template>

<script>
import clientUtils from "@/js/clientUtils";
import {exportExcelByTableId} from "@/js/excel";

export default {
  name: "DayForm",
  data(){
    return{
      title:"title",
      userInfo:"",
      dayFormData:[],
      days:[],
      options: [{
        value: '水',
        label: '水'
      }, {
        value: '电',
        label: '电'
      }, {
        value: '热',
        label: '热'
      }],
      months: [{        //日报表月份切换select
        value: '水',
        label: '11月'
      }]
      ,
      getMonth:""     //查询的日报表的月份
      ,
      value: ''
    }
  },
  created() {
    this.value="水"
    sessionStorage.setItem("dayFromType",this.value)
  },
  methods:{
    initTitle:function (){    //拼接title的方法，在挂载期调用
      this.userInfo = clientUtils.getLocalUserInfo();
      let myDate = new Date();
      let year = myDate.getFullYear()
      let month = myDate.getMonth()+1

      if (month >= 11){
        this.title=year+"~"+(year+1)+"年度采暖期"+this.userInfo.allowCompanys+"服务部换热站"+month+"月份"+this.value+"耗统计表"
      }else {
        this.title=year-1+"~"+year+"年度采暖期"+this.userInfo.allowCompanys+"服务部换热站"+month+"月份"+this.value+"耗统计表"
      }

      this.days = this.getDaysInMonth(year,month)
      this.getMonth = year+"-"+month;
      this.months.length = 0;

      //定义月份切换的值

      let a1;
      let a2;
      let a3;
      let a4;
      let a5;

      if (month >= 11){
         a1 = {lable:year+"-"+"11",value:year+"-"+"11"}
         a2 = {lable:year+"-"+"12",value:year+"-"+"12"}
         a3 = {lable:(year+1)+"-"+"1",value:(year+1)+"-"+"1"}
         a4 = {lable:(year+1)+"-"+"2",value:(year+1)+"-"+"2"}
         a5 = {lable:(year+1)+"-"+"3",value:(year+1)+"-"+"3"}
      }else {
        a1 = {lable:year-1+"-"+"11",value:year-1+"-"+"11"}
        a2 = {lable:year-1+"-"+"12",value:year-1+"-"+"12"}
        a3 = {lable:(year)+"-"+"1",value:(year)+"-"+"1"}
        a4 = {lable:(year)+"-"+"2",value:(year)+"-"+"2"}
        a5 = {lable:(year)+"-"+"3",value:(year)+"-"+"3"}
      }


      this.months.push(a1)
      this.months.push(a2)
      this.months.push(a3)
      this.months.push(a4)
      this.months.push(a5)

    },
    updateTitle:function (){
      let str = this.getMonth.split("-")
      let year = str[0]
      let month = str[1]

      if(month >= 11){
        this.title=year+"~"+(parseInt(year)+1)+"年度采暖期"+this.userInfo.allowCompanys+"服务部换热站"+month+"月份"+this.value+"耗统计表"
      }else {
        this.title=(parseInt(year)-1)+"~"+(parseInt(year))+"年度采暖期"+this.userInfo.allowCompanys+"服务部换热站"+month+"月份"+this.value+"耗统计表"
      }
    }
    ,


    getDaysInMonth:function (year, month) {
      const daysOfMonth = [];
      month = parseInt(month, 10);
      const lastDayOfMonth = new Date(year, month, 0).getDate();
      for (let i = 1; i <= lastDayOfMonth; i++) {
        if (i < 10) {
          daysOfMonth.push(month+"月" + i+"日"); //判断是部否小于10，如果小于加0，，例如“01”
        } else {
          daysOfMonth.push(month+"月"+i+"日");
        }
      }

      return daysOfMonth;
    },

    goToInitData:function (){
      sessionStorage.setItem("dayFromType",this.value)
      this.$router.push({name:'DayFormInitData',params:{user:this.value}})
    },
    getData(){
      let str = this.getMonth.split("-")
      let year = str[0]
      let month = str[1]
      this.days.length = 0;
      this.days = this.getDaysInMonth(year,month)
      const that = this
      clientUtils.get("/system/meter/getDayFromWater?month="+this.getMonth+"&type="+this.value, function (res) {
        if (res.data != null) {
          console.log("数据："+res.data.data)
          that.dayFormData=res.data.data
        }
      })
      this.updateTitle()
    },
    exportTable:function (){
      exportExcelByTableId("myTable","日报表")
    }

  },
  mounted() {
    this.initTitle()
    this.getData()

  }


}
</script>

<style scoped>



</style>
